@import "minima";

.copyright {
  font-size: 12px;
  color: $grey-color;
}

.hidden {
  display: none;
}

.verification-links {
  display: none;
}
